<template>
  <!-- 卡密 - 卡密发放列表 -->
  <div class="pageContol listWrap grantList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">卡密管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">卡密发放列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="卡名称" class="searchboxItem ci-full">
              <span class="itemLabel">卡名称:</span>
              <el-input
                size="small"
                v-model="queryData.cardName"
                type="text"
                placeholder="请输入卡名称"
                clearable
              />
            </div>
            <div title="卡类型" class="searchboxItem ci-full">
              <span class="itemLabel">卡类型:</span>
              <el-select
                v-model="queryData.cardType"
                placeholder="请选择卡类型"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in cardTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
              <div title="所属机构" class="searchboxItem ci-full">
              <span class="itemLabel">所属机构:</span>
              <el-input
                size="small"
                v-model="queryData.compName"
                type="text"
                placeholder="请输入所属机构"
                clearable
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="addEdit"
              >生成卡密</el-button
            >
          </div>
        </div>
        <p
          v-if="grantTotal && grantTotal != 0"
          style="padding: 0 0.675rem; padding-top: 10px"
        >
          发放总张数：<span>{{ grantTotal }}</span
          >张
        </p>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                min-width="60"
              ></el-table-column>
              <el-table-column
                label="卡名称"
                align="left"
                show-overflow-tooltip
                min-width="130"
                prop="walletName"
              ></el-table-column>
              <el-table-column
                label="所属机构"
                align="left"
                show-overflow-tooltip
                min-width="240"
                prop="compName"
              ></el-table-column>
              <el-table-column
                label="卡类型"
                align="left"
                show-overflow-tooltip
                 min-width="100"
                prop="noticeTitle"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("WALLETTYPE", scope.row.walletType || "--")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="发放数量"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="issuedNum"
              ></el-table-column>
              <el-table-column
                label="已兑换人数"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="useNum"
              ></el-table-column>
              <el-table-column
                label="商品有效期"
                align="left"
                show-overflow-tooltip
                min-width="80"
                prop="validPeriod"
              >
                <template slot-scope="scope">
                  {{ scope.row.validPeriod }}
                </template>
              </el-table-column>
              <el-table-column
                label="兑换有效期"
                align="left"
                show-overflow-tooltip
                min-width="260"
                prop="endDate"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate | moment }} - {{ scope.row.endDate | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="生成时间"
                align="left"
                show-overflow-tooltip
                min-width="150"
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="220"
                fixed="right"
                ><template slot-scope="scope">
                  <el-button type="text" size="mini" @click="addEdit(scope.row)"
                    >追加数量</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="seeDetailed(scope.row.walletId)"
                    >明细</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="exportCardNumber(scope.row.walletId)"
                    >批量导出卡号</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="downloadCardNoodles(scope.row.walletId)"
                    >批量下载卡面</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "grantList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 查询数据
      queryData: {
        cardName: "", // 卡密名称
        cardType: "", // 卡密类型
        compName:'', //所属机构
      },
      // 卡密类型 - 下拉数据
      cardTypes: [],
      // 总发放数量
      grantTotal: null,
    };
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
     $route: {
      handler: function(n, o) {
        if (n.path == "/web/grantList" && o.path == "/web/grantListInfo") {
          setTimeout(()=>{
            this.getData();
          },500)
        }
      },
      deep: true
    }
  },
  methods: {
    // 获取 - 卡密类型 - 下拉数据
    getnoticeify() {
      const list = this.$setDictionary("WALLETTYPE", "list") || [];
      for (let key in list) {
        this.cardTypes.push({ id: key, name: list[key] });
      }
    },
    // 获取发放卡密列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        walletName: this.queryData.cardName,
        walletType: this.queryData.cardType,
        compName: this.queryData.compName,
      };
      this.doFetch(
        {
          url: "/biz/card/wallet/queryList",
          params,
          pageNum,
        },
        true,
        2
      );
      // 总发放数量
      this.$post(
        "/biz/card/wallet/queryTotalNum",
        {
          walletName: this.queryData.cardName,
          walletType: this.queryData.cardType,
          compName: this.queryData.compName,
        },
        3000,
        false,
        2
      )
        .then((res) => {
          this.grantTotal = res.data;
        })
        .catch(() => {});
    },
    // 生成卡密/追加数量
    addEdit(row) {
      if (row.walletId) {
        if (!row.allowAppend) {
          this.$router.push({
            path: "/web/grantListInfo",
            query: {
              walletId: row.walletId,
            },
          });
        } else {
          this.$message.warning("兑换有效期已过，无法追加数量!");
        }
      } else {
        this.$router.push("/web/grantListInfo");
      }
    },
    // 查看明细
    seeDetailed(id) {
      this.$router.push({
        path: "/web/grantListSeeDetailed",
        query: {
          walletId: id,
        },
      });
    },
    // 批量导出卡号
    exportCardNumber(id) {
      this.$post(
        "/biz/card/wallet/exportList",
        {
          walletId: id,
        },
        3000,
        false,
        2
      )
        .then((res) => {
          window.location.href = res.data;
        })
        .catch(() => {});
    },
    // 批量下载卡面
    downloadCardNoodles(id) {
      // this.$post(
      //   "/biz/card/wallet/batchDownloadCard",
      //   {
      //     walletId: id
      //   },
      //   3000,
      //   false,
      //   2
      // )
      //   .then((res) => {
      //     // window.location.href = res.data;
      //     
      //   })
      //   .catch(() => {});
      this.$message.warning("暂未开通该功能!");
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  computed: {},
  mounted() {},
  created() {
    this.getnoticeify();
  },
};
</script>
  
 <style lang="less" scoped>
.grantList {
}
</style>